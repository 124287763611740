import { Token } from "@@app/store/slices/settings";
import ApiService from "./ApiService";
import { SettingsData, WhatsappPhoneType } from "@src/@types/settings";

export const getSettingsApi = () => {
  return ApiService.fetchData<SettingsData>({
    url: "/settings",
    method: "get",
  });
};

export const getTokensApi = () => {
  return ApiService.fetchData<Token[]>({
    url: "/settings/token",
    method: "get",
  });
};
export const createTokensApi = () => {
  return ApiService.fetchData<Token>({
    url: "/settings/token",
    method: "post",
  });
};

export const deleteTokensApi = (id: number) => {
  return ApiService.fetchData<Token>({
    url: `/settings/token/${id}`,
    method: "delete",
  });
};

export const updateSettingsApi = (data: SettingsData) => {
  return ApiService.fetchData<SettingsData>({
    url: "/settings",
    method: "post",
    data,
  });
};
export const getConfigApi = () => {
  return ApiService.fetchData({
    url: "/settings/config",
    method: "get",
  });
};
export const instagramLogoutApi = () => {
  return ApiService.fetchData({
    url: "/login/instagram_logout",
    method: "get",
  });
};
export const facebookLogoutApi = () => {
  return ApiService.fetchData({
    url: "/login/facebook_logout",
    method: "get",
  });
};
export const getWhatsappPhonesApi = () => {
  return ApiService.fetchData<WhatsappPhoneType[]>({
    url: "/login/whatsapp_phones",
    method: "get",
  });
};
export const registerWhatsappPhoneApi = (data) => {
  return ApiService.fetchData({
    url: "/login/whatsapp_register",
    method: "post",
    data,
  });
};
export const getInstagramLoginApi = () => {
  return ApiService.fetchData({
    url: "/login/instagram_login",
    method: "get",
  });
};
export const getFacebookLoginApi = () => {
  return ApiService.fetchData({
    url: "/login/facebook_login",
    method: "get",
  });
};

export const getWhatsappLoginApi = () => {
  return ApiService.fetchData({
    url: "/login/whatsapp_login",
    method: "get",
  });
};

export const whatsappRegister = (data) => {
  return ApiService.fetchData({
    url: "/login/whatsapp_register",
    method: "post",
    data,
  });
};
