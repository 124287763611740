import {
  createTokensApi,
  deleteTokensApi,
  facebookLogoutApi,
  getConfigApi,
  getSettingsApi,
  getTokensApi,
  getWhatsappPhonesApi,
  instagramLogoutApi,
  registerWhatsappPhoneApi,
  updateSettingsApi,
} from "@@services/SettingsService";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SettingsData, WhatsappPhoneType } from "@src/@types/settings";

type Config = {
  product?: "whatsbot" | "rasael" | "rasael-pro";
  whatsappPhone?: string;
  messengerId?: string;
  instagramId?: string;
  clientId?: string;
  activeProducts?: string[];
};

export type Token = {
  id: number;
  token: string;
};

export type SettingsSlice = {
  data: SettingsData;
  config?: Config;
  tokens?: Array<Token>;
  whatsappPhones?: Array<WhatsappPhoneType>;
};

const initialState: SettingsSlice = {
  data: {},
  config: {},
  tokens: [],
  whatsappPhones: [],
};

export const getConfig = createAsyncThunk("settings/getConfig", async () => {
  const response = await getConfigApi();
  return response.data;
});

export const getSettings = createAsyncThunk(
  "settings/getSettings",
  async () => {
    const response = await getSettingsApi();
    return response.data;
  }
);
export const instagramLogout = createAsyncThunk(
  "settings/instagramLogout",
  async () => {
    const response = await instagramLogoutApi();
    return response.data;
  }
);
export const facebookLogout = createAsyncThunk(
  "settings/facebookLogout",
  async () => {
    const response = await facebookLogoutApi();
    return response.data;
  }
);

export const getTokens = createAsyncThunk("settings/getTokens", async () => {
  const response = await getTokensApi();
  return response.data;
});

export const deleteTokens = createAsyncThunk(
  "settings/deleteTokens",
  async (id: number) => {
    const response = await deleteTokensApi(id);
    return response.data;
  }
);
export const createTokens = createAsyncThunk(
  "settings/createTokens",
  async () => {
    const response = await createTokensApi();
    return response.data;
  }
);

export const updateSettings = createAsyncThunk(
  "settings/updateSettings",
  async (data: SettingsData) => {
    const response = await updateSettingsApi(data);
    return response.data;
  }
);
export const getWhatsappPhones = createAsyncThunk(
  "settings/getWhatsappPhones",
  async () => {
    const response = await getWhatsappPhonesApi();
    return response.data;
  }
);

export const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        state.data = action.payload ? action.payload : {};
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.data = { ...action.payload };
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.config = action.payload;
      })
      .addCase(getTokens.fulfilled, (state, action) => {
        state.tokens = action.payload;
      })
      .addCase(deleteTokens.fulfilled, (state, action) => {
        state.tokens = state.tokens.filter(
          (token) => token.id !== action.payload.id
        );
      })
      .addCase(instagramLogout.fulfilled, (state, action) => {
        state.config.instagramId = undefined;
      })
      .addCase(facebookLogout.fulfilled, (state, action) => {
        state.config.messengerId = undefined;
      })
      .addCase(getWhatsappPhones.fulfilled, (state, action) => {
        state.whatsappPhones = action.payload;
      })
      .addCase(createTokens.fulfilled, (state, action) => {
        state.tokens.push(action.payload);
      });
  },
});

export const userPhone = (state: RootState) =>
  state.settingsSlice.config.whatsappPhone;
export const messengerId = (state: RootState) =>
  state.settingsSlice.config.messengerId;
export const instagramId = (state: RootState) =>
  state.settingsSlice.config.instagramId;
export const activeProducts = (state: RootState) =>
  state.settingsSlice.config.activeProducts;
export const whatsappPhones = (state: RootState) =>
  state.settingsSlice.whatsappPhones;

export const settingsData = (state: RootState) => state.settingsSlice.data;
export const configData = (state: RootState) => state.settingsSlice.config;
export const tokens = (state: RootState) => state.settingsSlice.tokens;
