import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const MessengerApp = lazyWithRetries(() => import("./ConsultationApp"));

/**
 * The chat app config.
 */
const ConsultationAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "consultations",
      element: <MessengerApp />,
      auth: [Role.CONSULTATION_PAGE],
    },
  ],
};

export default ConsultationAppConfig;
